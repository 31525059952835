/* eslint-disable import/max-dependencies */
import React, { useState } from "react";
import PatternLayout from "../../components/layouts/pattern-layout";
import Flex from "../../../packages/Flex/src";
import Div from "../../../packages/Div/src";
import Container from "../../../packages/Container/src";
import Logo from "../../../packages/Logo/src";
import Button from "../../../packages/Button/src";
import Link from "../../../packages/Link/src";
import Icon from "../../../packages/Icon/src";
import Divider from "../../../packages/Divider/src";
import { P, Span } from "../../../packages/Typography/src";
import { DrawerContainer, DrawerToggle, Drawer } from "../../../packages/Drawer/src";
import Modal from "../../../packages/Modal/src";
import { Menu, MenuItem, MenuDisclosure, MenuPanel } from "../../../packages/Menu/src";

var CalloutPattern = function CalloutPattern() {
  var _useState = useState(false),
      minimal = _useState[0],
      setMinimal = _useState[1];

  return React.createElement(PatternLayout, {
    noNavigation: true,
    bg: "#00ADEE"
  }, React.createElement(Div, {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    boxShadow: minimal ? "none" : "md",
    bg: minimal ? "transparent" : "white" // py="0.75rem"

  }, React.createElement(Div, {
    bg: "green.500",
    py: "0.5rem",
    px: "1.25rem",
    textAlign: "center",
    display: minimal ? "none" : "block"
  }, "Datastory is now available in 3 more languages! Learn more."), React.createElement(Container, {
    py: "0.75rem"
  }, React.createElement(Flex, {
    alignItems: "center",
    justifyContent: "space-between"
  }, React.createElement(Div, {
    flex: ["1 1 auto", null, "none"],
    pr: "1.25rem"
  }, React.createElement(Link, {
    href: "/",
    variant: "stealth",
    fontWeight: "600",
    style: {
      verticalAlign: "middle",
      display: "inline-block"
    }
  }, React.createElement(Logo, {
    outlined: false,
    color: "white"
  }))), !minimal && React.createElement(Div, {
    flex: "1 1 auto",
    display: ["none", null, "block"]
  }, React.createElement(Flex, {
    alignItems: "center"
  }, React.createElement(Link, {
    href: "/",
    variant: "stealth",
    fontWeight: "600",
    mr: "1.25rem"
  }, "Data stories"), React.createElement(Link, {
    href: "/",
    variant: "stealth",
    fontWeight: "600",
    mr: "1.25rem"
  }, "Topics"), React.createElement(Link, {
    href: "/",
    variant: "stealth",
    fontWeight: "600",
    mr: "1.25rem"
  }, "Charts"), React.createElement(Link, {
    href: "/",
    variant: "stealth",
    fontWeight: "600",
    mr: "1.25rem"
  }, "Data"), React.createElement(Link, {
    href: "/",
    variant: "stealth",
    fontWeight: "600"
  }, "Blog"), React.createElement(Menu, null, React.createElement(MenuDisclosure, {
    as: Button,
    variant: "ghost"
  }, "Topics", React.createElement(Icon, {
    name: "chevron_down",
    ml: "0.25rem",
    size: "1.25rem",
    color: "grey.500"
  })), React.createElement(MenuPanel, null, React.createElement(Span, {
    fontSize: "xs",
    fontWeight: "700",
    textTransform: "uppercase",
    muted: true,
    px: "1.25rem"
  }, "Popular topics"), React.createElement(MenuItem, {
    as: Link,
    variant: "stealth",
    href: "/"
  }, React.createElement(Span, {
    fontWeight: "700",
    fontSize: "sm"
  }, "School inequality in Sweden"), " ", React.createElement("br", null), React.createElement(Span, {
    fontSize: "xs",
    muted: true
  }, "Some topic subtitle")), React.createElement(MenuItem, {
    as: Link,
    variant: "stealth",
    href: "/"
  }, React.createElement(Span, {
    fontWeight: "700",
    fontSize: "sm"
  }, "Global development"), React.createElement("br", null), React.createElement(Span, {
    fontSize: "xs",
    muted: true
  }, "Some topic subtitle")), React.createElement(MenuItem, {
    as: Link,
    variant: "stealth",
    href: "/"
  }, React.createElement(Span, {
    fontWeight: "700",
    fontSize: "sm"
  }, "Swedish municipalities"), React.createElement("br", null), React.createElement(Span, {
    fontSize: "xs",
    muted: true
  }, "Some topic subtitle")), React.createElement(MenuItem, {
    as: Link,
    variant: "stealth",
    href: "/"
  }, React.createElement(Span, {
    fontWeight: "700",
    fontSize: "sm"
  }, "Swedish media"), React.createElement("br", null), React.createElement(Span, {
    fontSize: "xs",
    muted: true
  }, "Some topic subtitle")), React.createElement(MenuItem, {
    as: Link,
    variant: "stealth",
    href: "/"
  }, React.createElement(Span, {
    fontWeight: "600"
  }, "See all topics", React.createElement(Icon, {
    name: "arrow_right",
    size: "1.25rem",
    ml: "0.5rem"
  }))))))), !minimal && React.createElement(Flex, {
    alignItems: "center",
    display: ["none", "block"]
  }, React.createElement(Link, {
    href: "/",
    variant: "stealth",
    mr: "1.25rem",
    fontWeight: "600"
  }, "About"), React.createElement(Button, {
    borderRadius: "circle",
    width: "md",
    px: "0",
    mr: "0.75rem"
  }, React.createElement(Icon, {
    name: "search"
  })), React.createElement(Modal, {
    disclosure: React.createElement(Button, {
      borderRadius: "circle",
      width: "md",
      px: "0"
    }, React.createElement(Icon, {
      name: "translate"
    }))
  }, React.createElement(Div, {
    maxWidth: "15rem"
  }, React.createElement(P, {
    mb: "0"
  }, "We are currently working on translating datastory.org. Get involved and help us!"))), React.createElement(Button, {
    px: "1rem",
    borderRadius: "md",
    variant: "subtle",
    mx: "0.75rem"
  }, "Subscribe")), React.createElement(Div, {
    flex: "none"
  }, React.createElement(DrawerContainer, {
    name: "DrawerContainer"
  }, React.createElement(DrawerToggle, {
    as: Button,
    borderRadius: "circle",
    width: "md",
    px: "0"
  }, React.createElement(Icon, {
    name: "menu"
  })), React.createElement(Drawer, {
    color: "text"
  }, React.createElement(Flex, {
    py: "0.75rem",
    px: "1.25rem",
    justifyContent: "space-between"
  }, React.createElement(Div, {
    flex: "none"
  }, React.createElement(Logo, null)), React.createElement(Div, {
    flex: "none"
  }, React.createElement(DrawerToggle, {
    as: Button,
    borderRadius: "circle",
    width: "md",
    px: "0"
  }, React.createElement(Icon, {
    name: "close"
  })))), React.createElement(Divider, {
    color: "grey.100"
  }), React.createElement(Button, {
    borderRadius: "none",
    width: "100%",
    style: {
      justifyContent: "start"
    }
  }, "Data stories"), React.createElement(Button, {
    borderRadius: "none",
    width: "100%",
    style: {
      justifyContent: "start"
    }
  }, "Topics"), React.createElement(Button, {
    borderRadius: "none",
    width: "100%",
    style: {
      justifyContent: "start"
    }
  }, "Charts"), React.createElement(Button, {
    borderRadius: "none",
    width: "100%",
    style: {
      justifyContent: "start"
    }
  }, "Data"), React.createElement(Button, {
    borderRadius: "none",
    width: "100%",
    style: {
      justifyContent: "start"
    }
  }, "Blog"), React.createElement(Divider, {
    color: "grey.100"
  }), React.createElement(Button, {
    borderRadius: "none",
    width: "100%",
    style: {
      justifyContent: "start"
    }
  }, "Newsletter"), React.createElement(Divider, {
    color: "grey.100"
  }), React.createElement(Button, {
    borderRadius: "none",
    width: "100%",
    style: {
      justifyContent: "start"
    }
  }, "About"), React.createElement(Button, {
    borderRadius: "none",
    width: "100%",
    style: {
      justifyContent: "start"
    }
  }, "Services"), React.createElement(Button, {
    borderRadius: "none",
    width: "100%",
    style: {
      justifyContent: "start"
    }
  }, "Team"), React.createElement(Button, {
    borderRadius: "none",
    width: "100%",
    style: {
      justifyContent: "start"
    }
  }, "Donate"), React.createElement(Divider, {
    color: "grey.100"
  }))))))), React.createElement(Div, {
    pt: "10rem",
    textAlign: "center"
  }, React.createElement(Container, null, React.createElement(Button, {
    variant: "subtle",
    onClick: function onClick() {
      return setMinimal(!minimal);
    }
  }, minimal ? "Show normal" : "Show minimal"))));
};

export default CalloutPattern;